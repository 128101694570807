import {inject, Injectable} from "@angular/core";
import {HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {ApiService} from "../services";
import {finalize} from "rxjs";

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {

  private apiService = inject(ApiService);

  constructor() {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    this.apiService.isLoading.set(true);

    return next.handle(req).pipe(
      finalize(() => this.apiService.isLoading.set(false)),
    );
  }
}
