import {computed, inject, Injectable, Signal} from "@angular/core";
import {HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {AuthService} from "../services";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private auth: AuthService = inject(AuthService);

  private authToken: Signal<string | null> = computed(() => this.auth.currentAccessToken());

  constructor() {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (!this.authToken()) {
      console.warn(`${this.constructor.name}: no accessToken found`, req.url)
      return next.handle(req);
    }

    const authReq = req.clone({setHeaders: {Authorization: `Bearer ${this.authToken()}`}});

    return next.handle(authReq);
  }
}
