import {Camera, CameraResultType} from "@capacitor/camera";
import {Injectable} from "@angular/core";
import {FilePicker, PickFilesResult} from "@capawesome/capacitor-file-picker";


@Injectable({
  providedIn: 'root'
})
export class CameraService {

  async takePictureToBase64String() {
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: true,
      resultType: CameraResultType.Base64
    });

    // image.webPath will contain a path that can be set as an image src.
    // You can access the original file using image.path, which can be
    // passed to the Filesystem API to read the raw data of the image,
    // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
    const imageBase64 = image.base64String;

    console.log(`${this.constructor.name}.takePicture: `, imageBase64?.length);

    return imageBase64;
  };

  async selectVideo(): Promise<PickFilesResult | null> {
    try {
      return await FilePicker.pickVideos();
    } catch (e) {
      console.warn(`${this.constructor.name}.selectVideo: `, e);
      return null;
    }
  }
}
