import {Component, inject} from '@angular/core';
import {addIcons} from "ionicons";
import {ellipse, person, square, triangle} from "ionicons/icons";
import {IonIcon, IonLabel, IonTabBar, IonTabButton, IonTabs, Platform} from "@ionic/angular/standalone";
import {NgIf} from "@angular/common";
import {NgIcon} from "@ng-icons/core";

@Component({
  selector: 'app-tabs',
  templateUrl: 'tabs.page.html',
  styleUrls: ['tabs.page.scss'],
  standalone: true,
  imports: [IonTabs, IonTabBar, IonTabButton, IonIcon, IonLabel, NgIf, NgIcon],
})
export class TabsPage {

  isMobile = false;

  private platform: Platform = inject(Platform);

  constructor() {
    addIcons({triangle, ellipse, square, person});

    this.isMobile = this.platform.is('mobileweb');
  }

}
