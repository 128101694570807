import {Injectable} from '@angular/core';
import {ModalController} from "@ionic/angular/standalone";
import {InstallIosSheetComponent} from "@app/components/install-ios-sheet/install-ios-sheet.component";

@Injectable({
  providedIn: 'root'
})
export class A2hsService {

  promptIntercepted = false;
  isStandalone = false;
  deferredPrompt: any | undefined;
  userInstalled = false;
  whereIsShare = 'bottom';

  // user agent
  isChrome = false;
  isExplorer = false;
  isExplorer_11 = false;
  isFirefox = false;
  isSafari = false;
  isOpera = false;
  isEdgeDesktop = false;
  isEdgeiOS = false;
  isEdgeAndroid = false;
  isAndroid = false;
  userAgent = '';

  isIOS = false;
  isMobile = false;

  // For testing debug display only
  promptSaved = false;
  customButtonClicked = false;
  deferredPromptShown = false;
  deferredPromptRejected = false;

  // Detects if device is in standalone mode
  // isInStandaloneMode = () => ('standalone' in window.navigator);

  constructor(private modalController: ModalController) {
    console.log(`${this.constructor.name}.constructor`);
  }

  checkUserAgent() {
    this.userAgent = navigator.userAgent.toLowerCase();
    const uaString = this.userAgent;

    this.isChrome = /chrome/.test(uaString);
    this.isExplorer = /msie/.test(uaString);
    this.isExplorer_11 = /rv:11/.test(uaString);
    this.isFirefox = /firefox/.test(uaString);
    this.isSafari = /safari/.test(uaString);
    this.isOpera = /opr/.test(uaString);
    this.isEdgeDesktop = /edge/.test(uaString);
    this.isEdgeiOS = /edgios/.test(uaString);
    this.isEdgeAndroid = /edga/.test(uaString);
    this.isAndroid = /android/.test(uaString);

    this.isIOS = /ipad|iphone|ipod/.test(uaString);
    this.isMobile = /mobile/.test(uaString);

    if (!this.isMobile) {
      this.isMobile = /android/.test(uaString);
    }

    if ((this.isChrome) && (this.isSafari)) {
      this.isSafari = false;
    }
    if ((this.isChrome) && ((this.isEdgeDesktop) ||
      (this.isEdgeiOS) ||
      (this.isEdgeAndroid))) {
      this.isChrome = false;
    }
    if ((this.isSafari) && ((this.isEdgeDesktop) ||
      (this.isEdgeiOS) ||
      (this.isEdgeAndroid))) {
      this.isSafari = false;
    }
    if ((this.isChrome) && (this.isOpera)) {
      this.isChrome = false;
    }

    if (/ipad/.test(uaString)) {
      this.whereIsShare = 'top';
    }

    console.log(`${this.constructor.name}.checkUserAgent: isMobile:`, this.isMobile, 'isAndroid', this.isAndroid, 'isIOS', this.isIOS, uaString);
  }

  trackStandalone() {
    // called once from app.component
    if (this.checkStandalone()) {
      this.isStandalone = true;
    }

    console.log(`${this.constructor.name}.trackStandalone():`, this.isStandalone);
  }

  checkStandalone(): boolean {
    const result = (window.matchMedia('(display-mode: standalone)').matches);
    console.log(`${this.constructor.name}.checkStandalone():`, result);

    return result;
  }

  trackInstalled() {
    // called from listener in app.component
    console.log('setting this.userInstalled true');
    this.userInstalled = true;
  }

  addToHomeScreen() {
    // call on custom button click
    this.customButtonClicked = true;
    console.log(`${this.constructor.name}.addToHomeScreen`);

    if (!this.deferredPrompt) {
      console.log(`${this.constructor.name}.addToHomeScreen: deferredPrompt is null`);
      return;
    }

    // Show the prompt
    this.deferredPrompt.prompt();
    this.deferredPromptShown = true;

    // Wait for the user to respond to the prompt
    this.deferredPrompt.userChoice
      .then((choiceResult: { outcome: string; }) => {

        if (choiceResult.outcome === 'accepted') {
          // no matter the outcome, the prompt cannot be reused ON MOBILE
          // for 3 months or until browser cache is cleared?
        } else {
          this.deferredPromptRejected = true;
        }

      });
  }

  async addToHomeScreenIos() {
    const modal = await this.modalController.create({
      component: InstallIosSheetComponent,
      cssClass: 'auto-height',
    });

    modal.addEventListener('close-install-ios-sheet', () => {
      modal.dismiss();
    });

    await modal.present();
  }

  showHide(checkWhat: boolean) {
    if (checkWhat) {
      return 'block';
    } else {
      return 'none';
    }
  }

  browserPromptBtn() {
    if (this.promptIntercepted && !this.userInstalled) {
      return 'block';
    } else {
      return 'none';
    }
  }

  iOSSafariHow2() {
    if (this.isSafari && this.isIOS && !this.isStandalone) {
      return 'block';
    } else {
      return 'none';
    }
  }


  showHideButton_iOS() {
    if (this.isIOS && !this.userInstalled) {
      return 'block';
    } else {
      return 'none';
    }
  }

  trueOrFalse(checkWhat: boolean) {
    if (checkWhat) {
      return 'green';
    } else {
      return 'red';
    }
  }
}
