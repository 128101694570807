import {Injectable} from "@angular/core";
import {PreferencesSheetComponent} from "@app/components/preferences-sheet/preferences-sheet.component";
import {ModalController} from "@ionic/angular/standalone";
import {TimelineEntry} from "@interfaces/timeline-entry";

@Injectable({
  providedIn: 'root'
})
export class PreferencesSheetService {
  private modal: HTMLIonModalElement | undefined;

  constructor(private modalController: ModalController) {
  }

  async openPreferences(entry: TimelineEntry) {
    console.log('open preferences');

    this.modal = await this.modalController.create({
      component: PreferencesSheetComponent,
      componentProps: {timelineEntry: entry},
      cssClass: 'auto-height',
    });

    this.modal.addEventListener('close-preferences', () => {
      this.close();
    });

    await this.modal.present();
  }

  async close() {
    await this.modal?.dismiss();
  }
}
