@if (!a2hs.isIOS && a2hs.promptIntercepted && !a2hs.isStandalone && a2hs.isMobile && !a2hs.userInstalled) {
  <ion-button class="btn-a2hs" (click)="a2hs.addToHomeScreen()">
    App installieren
  </ion-button>
}

@if(a2hs.isIOS && !a2hs.isStandalone){
  <ion-button class="btn-a2hs" (click)="a2hs.addToHomeScreenIos()">
    App installieren
  </ion-button>
}
