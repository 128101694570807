import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {OnboardingSheet} from "@app/interfaces";
import {ApiService} from "@services/api.service";

@Injectable({
  providedIn: 'root'
})
export class OnboardingSheetsService extends ApiService {

  fetch(): Observable<OnboardingSheet[]> {
    console.log(`${this.constructor.name}.fetch`);
    return this.get(`/onboarding-sheets`);
  }
}
