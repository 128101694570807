import {inject, Injectable} from '@angular/core';
import {FirebaseMessaging, GetTokenOptions} from "@capacitor-firebase/messaging";
import {environment} from "@env/environment";
import {initializeApp} from "firebase/app";
import {Capacitor} from "@capacitor/core";
import {firstValueFrom} from "rxjs";
import {User} from "@app/interfaces";
import {AuthService} from "@services/auth.service";
import {UserService} from "@services/api/user.service";
import {NavController} from "@ionic/angular/standalone";

@Injectable({
  providedIn: 'root'
})
export class MessagingService {
  private authService: AuthService = inject(AuthService);
  private userService: UserService = inject(UserService);
  private navController: NavController = inject(NavController);

  constructor() {
    try {
      this.initializeFirebaseSdk();
      this.initializeFirebase();
    } catch (error) {
      console.error(`${this.constructor.name}.constructor: error`, error);
    }
  }

  public async checkPermissions(): Promise<boolean> {
    console.log(`${this.constructor.name}.checkPermissions`);

    const result = await FirebaseMessaging.checkPermissions().catch((error) => {
      console.error(`${this.constructor.name}.checkPermissions: error`, error);
      return {receive: "prompt"};
    });
    return result.receive === "granted";
  }

  public async requestPermissions(): Promise<void> {
    console.log(`${this.constructor.name}.requestPermissions`);

    const hasPermission = await this.checkPermissions();
    if (!hasPermission) {
      await FirebaseMessaging.requestPermissions().catch((error) => {
        console.error(`${this.constructor.name}.requestPermissions: error`, error);
      });
    }
  }

  public async getToken(): Promise<void> {
    console.log(`${this.constructor.name}.getToken`);
    const options: GetTokenOptions = {
      vapidKey: environment.firebase.vapidKey,
    };
    if (Capacitor.getPlatform() === "web") {
      options.serviceWorkerRegistration = await navigator.serviceWorker.register("firebase-messaging-sw.js");
    }
    const {token} = await FirebaseMessaging.getToken(options).catch((error) => {
      console.error(`${this.constructor.name}.getToken: error`, error);
      return {token: null};
    });
    console.log(`${this.constructor.name}.getToken: token`, token);

    if (token) {
      if (this.authService.fcmToken() !== token) {
        this.authService.fcmToken.set(token);
        if (this.authService.currentAccessToken() !== null) {
          const user = await firstValueFrom(this.userService.updateFCMToken(token));
          this.authService.updateUser(new User(user));
        }
      }
    }
    return;
  }

  private initializeFirebaseSdk() {
    if (Capacitor.isNativePlatform()) {
      console.log(`${this.constructor.name}.initializeFirebaseSdk: not initializing firebase for native platform`);
      return;
    }

    const firebaseApp = initializeApp(environment.firebase);
    console.log(`${this.constructor.name}.initializeFirebaseSdk`);
  }

  private initializeFirebase() {
    console.log(`${this.constructor.name}.initializeFirebase`);

    FirebaseMessaging.addListener("notificationReceived", (event) => {
      console.log(`${this.constructor.name}.initializeFirebase: notificationReceived: `, {event});
    });
    FirebaseMessaging.addListener("notificationActionPerformed", (event) => {
      console.log(`${this.constructor.name}.initializeFirebase: notificationActionPerformed: `, {event});
    });
    if (Capacitor.getPlatform() === "web") {
      navigator.serviceWorker.addEventListener("message", (event: any) => {
        console.log(`${this.constructor.name}.initializeFirebase: serviceWorker message: `, event.data.type, event);

        if (event.data.type == 'NOTIFICATION_CLICK') {
          const notification = event.data.data.notification;
          console.log(`${this.constructor.name}.initializeFirebase: notification_click data`, notification);

          if (notification.data && notification.data.type) {
            if (notification.data.link) {
              return this.navController.navigateRoot(notification.data.link);
            }

            if (event.data.data.type === 'event') {
              return this.navController.navigateRoot(`/events/${event.data.data.id}`);
            }
            if (event.data.data.type === 'news') {
              return this.navController.navigateRoot(`/news/${event.data.data.id}`);
            }
            if (event.data.data.type === 'place') {
              return this.navController.navigateRoot(`/places/${event.data.data.id}`);
            }
          }
        }

        return true;
      });
    }
  }
}
