import {computed, Injectable, signal, Signal, WritableSignal} from "@angular/core";
import {Share} from "@capacitor/share";

@Injectable({
  providedIn: 'root'
})
export class ShareService {

  canShare: Signal<boolean> = computed(() => this._canShare());
  private _canShare: WritableSignal<boolean> = signal(false);

  constructor() {
    this.init();
  }

  async share(title: string, url: string, dialogTitle: string) {
    console.log(`${this.constructor.name} share: `, title, url, dialogTitle);

    await Share.share({
      title: title,
      url: url,
      dialogTitle: dialogTitle,
    });
  }


  private async init() {
    const canShare = await Share.canShare();
    this._canShare.set(canShare.value);
    console.log(`${this.constructor.name} canShare: ${canShare.value}`);
  }
}
