import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {ApiService} from "@services/api.service";
import {SearchResult} from "@interfaces/search-result";

@Injectable({
  providedIn: 'root'
})
export class SearchService extends ApiService {

  search(term: string): Observable<SearchResult> {
    console.log(`${this.constructor.name}.search`);
    return this.post(`/search`, {term});
  }
}
