<ion-header>
  <ion-toolbar>
    <ion-title>
      @if (model.id) {
        Event bearbeiten
      } @else {
        Event erstellen
      }
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="close()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<div class="inner-content p-4">
  <form [formGroup]="form" (ngSubmit)="save()">
    <ion-list>
      <ion-item>
        <div class="w-full">
          <div class="w-full flex justify-between">
            <ion-label for="photoButton" class="block">Bild aufnehmen</ion-label>
            <ion-button id="fotoButton" (click)="takePicture()">
              <ion-icon slot="icon-only" name="camera"></ion-icon>
            </ion-button>
          </div>

          <img id="photo" class="w-full max-h-64" #photo alt="" [class.hidden]="!hasPhoto"/>
          @if (model.images && model.images.length > 0) {
            <div>
              <ion-label>Aktuelle Bilder (klicken zum entfernen)</ion-label>
              <div #photosContainer class="flex items-center overflow-y-scroll space-x-3 mb-3">
                @for (photo of model.images; track photo) {
                  <img class="w-full max-h-32 max-w-32 object-contain" (click)="removeImage(photo)" src="{{ photo }}" alt=""/>
                }
              </div>
            </div>
          }
        </div>
      </ion-item>
      <ion-item>
        <div class="w-full">
          <div class="w-full flex justify-between">
            <ion-label for="videoButton" class="block">
              Video hochladen
              <span *ngIf="form.get('videoPath')?.value">{{ form.get('videoPath')?.value }}</span>
            </ion-label>
            <ion-button id="videoButton" class="h-4 w-4" (click)="chooseVideo()">
              <ion-icon slot="icon-only" name="videocam"></ion-icon>
            </ion-button>
          </div>
        </div>
      </ion-item>

      <ion-item>
        <ion-input labelPlacement="floating" label="Name des Events" property="title" formControlName="title"/>
      </ion-item>

      <ion-item>
        <ion-textarea labelPlacement="floating" label="Beschreibung des Events" property="description"
                      formControlName="description"/>
      </ion-item>

      <ion-item>
        <ion-label>Start des Events</ion-label>
        <ion-datetime-button
          aria-label="Start des Events"
          datetime="event_date_start"
        />
      </ion-item>

      <!--            <ion-item>-->
      <!--              <ion-label>Ende des Events (optional)</ion-label>-->
      <!--              <ion-datetime-button-->
      <!--                aria-label="Ende des Events (optional)"-->
      <!--                datetime="event_date_end"-->
      <!--              />-->
      <!--            </ion-item>-->

      <ion-item>
        <ion-select formControlName="place_id" placeholder="Bitte wählen" label="Veranstalter">
          @for (place of places(); track place.id) {
            <ion-select-option [value]="place.id">{{ place.title }}</ion-select-option>
          }
        </ion-select>
      </ion-item>

    </ion-list>

    <ion-modal [keepContentsMounted]="true">
      <ng-template>
        <ion-datetime
          id="event_date_start"
          locale="de-DE"
          [min]="minDate"
          formControlName="event_date_start"
        />
      </ng-template>
    </ion-modal>

    <!--          <ion-modal [keepContentsMounted]="true">-->
    <!--            <ng-template>-->
    <!--              <ion-datetime-->
    <!--                id="event_date_end"-->
    <!--                locale="de-DE"-->
    <!--                [min]="minDate"-->
    <!--                formControlName="event_date_end"-->
    <!--              />-->
    <!--            </ng-template>-->
    <!--          </ion-modal>-->
  </form>
</div>

<ion-footer>
  <ion-toolbar class="ion-padding-horizontal">
    <ion-button expand="block" fill="solid" color="primary" class="cursor-pointer" (click)="save()" [disabled]="isLoading()">
      @if (model.id) {
        Speichern
      } @else {
        Erstellen
      }
    </ion-button>
  </ion-toolbar>
</ion-footer>
