import {Injectable} from "@angular/core";
import {createState, select, Store, withProps} from "@ngneat/elf";
import {localStorageStrategy, persistState} from "@ngneat/elf-persist-state";
import {User} from "@app/interfaces";

interface Settings {
  hasSeenOnboarding: boolean,
  news_updated_at: string | null,
  events_updated_at: string | null,
  places_updated_at: string | null,
  user: User | null,
  accessToken: string | null,
}

const {state, config} = createState(withProps<Settings>({
  hasSeenOnboarding: false,
  news_updated_at: null,
  events_updated_at: null,
  places_updated_at: null,
  user: null,
  accessToken: null,
}));
const settingsStore = new Store({name: 'settings', state, config});

const persist = persistState(settingsStore, {
  key: 'settings',
  storage: localStorageStrategy,
});

@Injectable({providedIn: 'root'})
export class SettingsRepository {
  settings$ = settingsStore.pipe(select((state) => state));
  newsUpdatedAt$ = settingsStore.pipe(select((state) => state.news_updated_at));
  eventsUpdatedAt$ = settingsStore.pipe(select((state) => state.events_updated_at));
  placesUpdatedAt$ = settingsStore.pipe(select((state) => state.places_updated_at));

  setHasSeenOnboarding() {
    settingsStore.update((state) => ({
      ...state,
      hasSeenOnboarding: true,
    }));
  }

  setNewsUpdatedAt(date: string = new Date().toISOString()) {
    settingsStore.update((state) => ({
      ...state,
      news_updated_at: date,
    }));
  }

  removeNewsUpdatedAt() {
    settingsStore.update((state) => ({
      ...state,
      news_updated_at: null,
    }));
  }

  setEventsUpdatedAt(date: string = new Date().toISOString()) {
    settingsStore.update((state) => ({
      ...state,
      events_updated_at: date,
    }));
  }

  removeEventsUpdatedAt() {
    settingsStore.update((state) => ({
      ...state,
      events_updated_at: null,
    }));
  }

  setPlacesUpdatedAt(date: string = new Date().toISOString()) {
    settingsStore.update((state) => ({
      ...state,
      places_updated_at: date,
    }));
  }

  removePlacesUpdatedAt() {
    settingsStore.update((state) => ({
      ...state,
      places_updated_at: null,
    }));
  }

  setUser(user: User) {
    settingsStore.update((state) => ({
      ...state,
      user,
    }));
  }

  removeUser() {
    settingsStore.update((state) => ({
      ...state,
      user: null,
    }));
  }

  setAccessToken(accessToken: string) {
    settingsStore.update((state) => ({
      ...state,
      accessToken,
    }));
  }

  removeAccessToken() {
    settingsStore.update((state) => ({
      ...state,
      accessToken: null,
    }));
  }

  constructor() {
    console.log(`${this.constructor.name}.constructor`, settingsStore);
    this.settings$.subscribe((settings) => {
      console.log(`${this.constructor.name}.settings$`, settings);
    });
  }

  updateSettings(settings: Settings) {
    console.log(`${this.constructor.name}.updateSettings`, settings);
  }
}
