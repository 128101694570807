import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  inject,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonTitle,
  IonToolbar
} from "@ionic/angular/standalone";
import {OnboardingSheet} from "@app/interfaces";
import {close} from "ionicons/icons";
import {addIcons} from "ionicons";

@Component({
  selector: 'app-onboarding-sheet',
  templateUrl: './onboarding-sheet.component.html',
  styleUrls: ['./onboarding-sheet.component.scss'],
  imports: [
    IonContent,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButton,
    IonButtons,
    IonIcon,
    IonFooter
  ],
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  encapsulation: ViewEncapsulation.None,
})
export class OnboardingSheetComponent implements OnInit {

  @Input() sheets: OnboardingSheet[] = [];
  @ViewChild('swiperEl') swiperEl: any;

  isLastSheet = false;

  private el: ElementRef = inject(ElementRef);

  constructor() {
    console.log(`${this.constructor.name}.constructor`);
    addIcons({close});
  }

  async ngOnInit() {
    console.log(`${this.constructor.name}.ngOnInit`, this.swiperEl);

    if (this.sheets.length === 1) {
      this.isLastSheet = true;
    }
  }

  ngAfterViewInit(): void {
    console.log(`${this.constructor.name}.ngAfterViewInit`, this.swiperEl);

    this.swiperEl.nativeElement.swiper.on('slideChange', () => {
      this.isLastSheet = this.swiperEl.nativeElement.swiper.isEnd;
    });
  }

  close() {
    this.el.nativeElement.dispatchEvent(new CustomEvent('close-onboarding-sheet', {bubbles: true}));
  }

  swipeNext() {
    this.swiperEl.nativeElement.swiper.slideNext();
  }
}
