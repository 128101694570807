import {Injectable} from "@angular/core";
import {createState, Store} from "@ngneat/elf";
import {selectAllEntities, selectAllEntitiesApply, selectEntity, setEntities, withEntities} from "@ngneat/elf-entities";
import {Place} from "@app/interfaces";
import {localStorageStrategy, persistState} from "@ngneat/elf-persist-state";
import {Observable} from "rxjs";

const {state, config} = createState(withEntities<Place>());

const placesStore = new Store({name: 'places', state, config});

const persist = persistState(placesStore, {
  key: 'places',
  storage: localStorageStrategy,
});

@Injectable({providedIn: 'root'})
export class PlacesRepository {
  places$ = placesStore.pipe(selectAllEntities());


  updateEntries(places: Place[]) {
    console.log(`${this.constructor.name}.updateEntries`, places.length);
    placesStore.update(setEntities(places));
  }

  filterById(id: string): Observable<Place | undefined> {
    return placesStore.pipe(selectEntity(id));
  }

  filterBySlug(slug: string): Observable<Place[]> {
    console.log(`${this.constructor.name}.filterBySlug`, slug);

    return placesStore.pipe(
      selectAllEntitiesApply({
        filterEntity: (place) => place.slug === slug
      })
    );
  }

  constructor() {
    console.log(`${this.constructor.name}.constructor`);

    this.places$.subscribe(places => {
      if (places) {
        console.log(`${this.constructor.name}.places$`, places.length);
      } else {
        console.log(`${this.constructor.name}.places$`, places);
      }
    });
  }
}
