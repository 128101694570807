import {Injectable} from '@angular/core';

import {Storage} from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private _storage: Storage | null = null;

  constructor(private storage: Storage) {
    console.log(`${this.constructor.name}.constructor`)
    this.init();
  }

  async init() {
    if (this._storage !== null) {
      return;
    }

    console.log(`${this.constructor.name}.init`);

    const storage = await this.storage.create();
    this._storage = storage;

    console.log(`${this.constructor.name}.init: storage`, this._storage !== null);
  }

  set(key: string, value: any): void {
    this._storage?.set(key, value);
  }

  async get(key: string) {
    console.log(`${this.constructor.name}.get: key`, key, this._storage !== null);

    // if (!this._storage) {
    //   await this.init();
    // }
    return this._storage?.get(key);
  }

  remove(key: string) {
    return this._storage?.remove(key);
  }
}
