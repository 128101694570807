<ion-header>
  <ion-toolbar>
    <ion-title>Pfaffenhofen App</ion-title>
    @if (isLastSheet) {
      <ion-buttons slot="end">
        <ion-button (click)="close()">
          <ion-icon slot="icon-only" name="close"></ion-icon>
        </ion-button>
      </ion-buttons>
    }
  </ion-toolbar>
</ion-header>

<div class="inner-content p-4">
  <swiper-container #swiperEl pagination="true" pagination-dynamic-bullets="false">
    @for (sheet of sheets; track sheet.id) {
      <swiper-slide>
        <div>
          <h1 class="text-3xl mb-3 text-center">{{ sheet.title }}</h1>

          @if (sheet.image) {
            <img [src]="sheet.image" class="w-1/2 m-auto mt-3 mb-6"/>
          }

          <div class="external-content" [innerHTML]="sheet.description"></div>
        </div>
      </swiper-slide>
    }
  </swiper-container>


</div>

<ion-footer>
  <ion-toolbar class="ion-padding-horizontal">
    @if (isLastSheet) {
      <ion-button size="block" (click)="close()" class="">Schließen</ion-button>
    } @else {
      <ion-button size="block" (click)="swipeNext()" class="">Weiter</ion-button>
    }
  </ion-toolbar>
</ion-footer>
