import {Place} from "@interfaces/place";

export interface IUser {
  id: string;
  first_name: string;
  last_name: string;
  name: string;
  email: string;
  phone: string;
  places: { id: string, title: string }[];
  teams: { name: string, slug: string }[];
  settings: IUserSettings,
  fcm_token: string | null;
}

export interface IUserSettings {
  news_preferences: {
    disabled: string[],
    notifications: string[]
  };
  event_preferences: {
    disabled: string[],
    notifications: string[]
  };
  place_preferences: {
    disabled: string[],
  };
}

export interface IVoluntaryActivity {
  id: string,
  title?: string,
}

export class User implements IUser {
  id: string;
  first_name: string;
  last_name: string;
  name: string;
  email: string;
  phone: string;
  places: { id: string, title: string }[];
  teams: { name: string, slug: string }[];
  settings: IUserSettings;
  fcm_token: string | null;

  constructor(user: IUser) {
    this.id = user.id;
    this.name = user.name;
    this.first_name = user.first_name;
    this.last_name = user.last_name;
    this.email = user.email;
    this.phone = user.phone;
    this.places = user.places;
    this.teams = user.teams;
    this.settings = user.settings;
    this.fcm_token = user.fcm_token;
  }

  getInitials() {
    return this.name.split(' ').map((n) => n.charAt(0)).join('');
  }

  ownsPlace(place: Place) {
    return this.places.some(p => p.id === place.id);
  }
}
