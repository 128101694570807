import {Component, ElementRef, inject} from '@angular/core';
import {Place} from "@app/interfaces";
import {HeaderComponent} from "@app/components/header/header.component";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonTitle,
  IonToolbar
} from "@ionic/angular/standalone";
import {AuthService} from "@app/services";
import {ContainerComponent} from "@app/components/container/container.component";
import {NgIcon} from "@ng-icons/core";
import {close} from "ionicons/icons";
import {addIcons} from "ionicons";

@Component({
  selector: 'app-install-ios-sheet',
  templateUrl: './install-ios-sheet.component.html',
  styleUrls: ['./install-ios-sheet.component.scss'],
  imports: [
    HeaderComponent,
    IonContent,
    IonHeader,
    IonToolbar,
    IonTitle,
    ContainerComponent,
    NgIcon,
    IonFooter,
    IonButton,
    IonButtons,
    IonIcon,
  ],
  standalone: true
})
export class InstallIosSheetComponent {

  public place: Place | undefined;

  public authService: AuthService = inject(AuthService);
  private el: ElementRef = inject(ElementRef);

  constructor() {
    console.log(`${this.constructor.name}.constructor`);
    addIcons({close});
  }

  close() {
    this.el.nativeElement.dispatchEvent(new CustomEvent('close-install-ios-sheet', {bubbles: true}));
  }
}
