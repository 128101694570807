import {ErrorHandler} from '@angular/core';
import {flare} from '@flareapp/flare-client';
import {environment} from "@env/environment";

export class ErrorHandlerFlare implements ErrorHandler {
  constructor() {
  }

  async handleError(error: Error) {
    flare.report(error, {
      apiUrl: environment.apiBaseUrl,
    });
  }
}
