import {ErrorHandler} from '@angular/core';

export class ErrorHandlerDebug implements ErrorHandler {
  constructor() {
  }

  async handleError(error: any) {
    console.error(error);
  }
}
