import {computed, inject, Injectable, Signal} from "@angular/core";
import {UserService} from "@services/api/user.service";
import {AuthService} from "@services/auth.service";
import {firstValueFrom} from "rxjs";
import {IUser, Owner, Place, User} from "@app/interfaces";
import {TimelineEntry} from "@interfaces/timeline-entry";

@Injectable({
  providedIn: 'root'
})
export class PreferencesService {

  private authService: AuthService = inject(AuthService);
  private userService: UserService = inject(UserService);

  disabledNews: Signal<string[]> = computed(() => this.authService.currentUser()?.settings.news_preferences.disabled || []);
  disabledEvents: Signal<string[]> = computed(() => this.authService.currentUser()?.settings.event_preferences.disabled || []);
  notificationsNews: Signal<string[]> = computed(() => this.authService.currentUser()?.settings.news_preferences.notifications || []);
  notificationsEvents: Signal<string[]> = computed(() => this.authService.currentUser()?.settings.event_preferences.notifications || []);

  constructor() {
  }

  isNotificationEnabled(entry: TimelineEntry) {
    if (entry.type === 'news') {
      return this.notificationsNews().includes(entry.owner.id);
    }

    return this.notificationsEvents().includes(entry.owner.id);
  }

  async disableEntry(place: Place | Owner, type: 'event' | 'news') {
    console.log(`${this.constructor.name}.disableEntry`);

    const user = this.authService.currentUser();
    if (user) {
      let updatedUser: IUser;
      if (type === 'news') {
        updatedUser = await firstValueFrom(this.userService.disableNews(place));
      } else {
        updatedUser = await firstValueFrom(this.userService.disableEvent(place));
      }

      this.authService.currentUser.set(new User(updatedUser));
    }
  }

  async enableEntry(place: Place | Owner, type: 'event' | 'news') {
    console.log(`${this.constructor.name}.enableEntry`);

    const user = this.authService.currentUser();
    if (user) {
      let updatedUser: IUser;
      if (type === 'news') {
        updatedUser = await firstValueFrom(this.userService.enableNews(place));
      } else {
        updatedUser = await firstValueFrom(this.userService.enableEvent(place));
      }

      this.authService.currentUser.set(new User(updatedUser));
    }
  }

  async disablePlace(place: Place | Owner) {
    console.log('disable place');

    const user = this.authService.currentUser();
    if (user) {
      let updatedUser = await firstValueFrom(this.userService.disableNews(place));
      updatedUser = await firstValueFrom(this.userService.disableEvent(place));

      this.authService.currentUser.set(new User(updatedUser));
    }
  }

  async enableNotification(place: Place | Owner, type: 'news' | 'event') {
    console.log(`${this.constructor.name}.enableNotification`);

    const user = this.authService.currentUser();
    if (user) {
      let updatedUser: IUser;
      updatedUser = await firstValueFrom(this.userService.enableNotification(place, type));
      this.authService.currentUser.set(new User(updatedUser));
    }
  }

  async disableNotification(place: Place | Owner, type: 'news' | 'event') {
    console.log(`${this.constructor.name}.disableNotification`);

    const user = this.authService.currentUser();
    if (user) {
      let updatedUser: IUser;
      updatedUser = await firstValueFrom(this.userService.disableNotification(place, type));
      this.authService.currentUser.set(new User(updatedUser));
    }
  }
}
