import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {ApiService} from "@services/api.service";
import {UserRegistration} from "@interfaces/user-registration";
import {IUser, IUserSettings, Owner, Place} from "@app/interfaces";

@Injectable({
  providedIn: 'root'
})
export class UserService extends ApiService {

  register(data: UserRegistration): Observable<IUser> {
    console.log(`${this.constructor.name}.register`);
    return this.post(`/user/register`, data);
  }

  forgotPassword(email: string): Observable<void> {
    console.log(`${this.constructor.name}.forgotPassword`);
    return this.post(`/user/forgot-password`, {email});
  }

  updatePreferences(settings: IUserSettings): Observable<IUser> {
    console.log(`${this.constructor.name}.updatePreferences`, settings);
    return this.put(`/user/preferences`, settings);
  }

  disableNews(place: Place | Owner): Observable<IUser> {
    console.log(`${this.constructor.name}.disableNews`, place.id);
    return this.put(`/user/preferences/news/disable`, {id: place.id});
  }

  enableNews(place: Place | Owner): Observable<IUser> {
    console.log(`${this.constructor.name}.enableNews`, place.id);
    return this.put(`/user/preferences/news/enable`, {id: place.id});
  }

  disableEvent(place: Place | Owner): Observable<IUser> {
    console.log(`${this.constructor.name}.disableEvent`, place.id);
    return this.put(`/user/preferences/event/disable`, {id: place.id});
  }

  enableEvent(place: Place | Owner): Observable<IUser> {
    console.log(`${this.constructor.name}.enableEvent`, place.id);
    return this.put(`/user/preferences/event/enable`, {id: place.id});
  }

  disableNotification(place: Place | Owner, type: 'news' | 'event'): Observable<IUser> {
    console.log(`${this.constructor.name}.disableEvent`, place.id);
    return this.put(`/user/preferences/notifications/disable`, {id: place.id, type: type});
  }

  enableNotification(place: Place | Owner, type: 'news' | 'event'): Observable<IUser> {
    console.log(`${this.constructor.name}.enableEvent`, place.id);
    return this.put(`/user/preferences/notifications/enable`, {id: place.id, type: type});
  }

  updateFCMToken(token: string): Observable<IUser> {
    console.log(`${this.constructor.name}.updateFCMToken`, token);
    return this.put(`/user/fcm-token`, {token});
  }
}
