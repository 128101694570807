import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {Category} from "@app/interfaces";
import {ApiService} from "@services/api.service";

@Injectable({
  providedIn: 'root'
})
export class CategoriesService extends ApiService {

  fetch(): Observable<Category[]> {
    console.log(`${this.constructor.name}.fetch`);
    return this.get(`/categories`);
  }

  fetchById(id: string): Observable<Category> {
    console.log(`${this.constructor.name}.fetchById`, id);
    return this.get(`/categories/${id}?include=companies,associations,places`);
  }
}
