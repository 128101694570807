export const environment = {
  production: true,
  apiBaseUrl: 'https://api.89284-app.de/api',
  maxUploadFileSize: 50 * 1024 * 1024,
  firebase: {
    apiKey: "AIzaSyCZS4Eglul4KZ_-rIEMzS07TyXG-ssEy-M",
    authDomain: "pfundig-app.firebaseapp.com",
    projectId: "pfundig-app",
    storageBucket: "pfundig-app.appspot.com",
    messagingSenderId: "714532552174",
    appId: "1:714532552174:web:711c5d9f8755c2c0b5e2ff",
    vapidKey: 'BLjuwI7grhyNuzH06HCgiCC2vLZvLRwnid_hjYdHnL47sST_EO66m60lSd-h3e9BifgrSqE3k4xwXGeqXu8V_Gg',
  }
};
