import {computed, inject, Injectable, Signal} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from "@app/services";
import {NavController} from "@ionic/angular/standalone";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {

  isAuthenticated = false;
  private authToken: Signal<string | null> = computed(() => this.auth.currentAccessToken());

  private auth: AuthService = inject(AuthService);
  private navController: NavController = inject(NavController);

  constructor() {
    this.isAuthenticated = this.authToken() !== null;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.isAuthenticated;
  }

}
