<ion-header>
  <ion-toolbar>
    <ion-title>Präferenzen</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="close()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<div class="inner-content">
  <ion-list>
    @if (timelineEntry) {
      @if (preferencesService.isNotificationEnabled(timelineEntry)) {
        <ion-item (click)="disableNotifications()">
          <div class="flex items-center truncate">
            <ng-icon name="heroBellSlash" class="mr-1 text-red-500"/>
            Benachrichtigungen für {{ timelineEntry.type === 'news' ? 'News' : 'Events' }} deaktivieren
          </div>
        </ion-item>
      } @else {
        <ion-item (click)="enableNotifications()">
          <div class="flex items-center truncate">
            <ng-icon name="heroBell" class="mr-1 text-green-600"/>
            Benachrichtigungen für {{ timelineEntry.type === 'news' ? 'News' : 'Events' }} aktivieren
          </div>
        </ion-item>
      }

      @if (timelineEntry.type === 'news') {
        <ion-item (click)="disableNews()">
          <div class="flex items-center truncate">
            <ng-icon name="heroEyeSlash" class="mr-1 text-red-500"/>
            Keine News mehr von {{ timelineEntry.owner.title }}
          </div>
        </ion-item>
      }

      @if (timelineEntry.type === 'event') {
        <ion-item (click)="disableEvents()">
          <div class="flex items-center truncate">
            <ng-icon name="heroEyeSlash" class="mr-1 text-red-500"/>
            Keine Events mehr von {{ timelineEntry.owner.title }}
          </div>
        </ion-item>
      }

      <ion-item (click)="disablePlace()">
        <div class="flex items-center truncate">
          <ng-icon name="heroXMark" class="mr-1 text-red-600"/>
          Nichts mehr von {{ timelineEntry.owner.title }}
        </div>
      </ion-item>

      @if (timelineEntry.type === 'news' && authService.canUpdate(timelineEntry)) {
        <ion-item (click)="updateNews()">
          <div class="flex items-center truncate">
            <ng-icon name="heroPencilSquare" class="mr-1"/>
            News Bearbeiten
          </div>
        </ion-item>
      }

      @if (timelineEntry.type === 'event' && authService.canUpdate(timelineEntry)) {
        <ion-item (click)="updateEvent()">
          <div class="flex items-center truncate">
            <ng-icon name="heroPencilSquare" class="mr-1"/>
            Event Bearbeiten
          </div>
        </ion-item>
      }
    }
  </ion-list>
</div>
