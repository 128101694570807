<ion-tabs>

  <ion-tab-bar slot="bottom" class="flex sm:hidden border-gray-500 border-t">
    <ion-tab-button tab="news" href="news">
      <ng-icon name="heroNewspaper" size="1.5rem"/>
      <ion-label>News</ion-label>
    </ion-tab-button>

    <ion-tab-button tab="events" href="events">
      <ng-icon name="heroCalendarDays" size="1.5rem"/>
      <ion-label>Events</ion-label>
    </ion-tab-button>

    <ion-tab-button tab="places" href="places/map">
      <ng-icon name="heroMapPin" size="1.5rem"/>
      <ion-label>Orte</ion-label>
    </ion-tab-button>

    <ion-tab-button tab="user/login" href="user/login">
      <ng-icon name="heroUser" size="1.5rem"/>
      <ion-label>Meins</ion-label>
    </ion-tab-button>
  </ion-tab-bar>

</ion-tabs>
